// export function slide(event) {
//   event.preventDefault();
//   event.stopPropagation();
//   const target = event.target.closest('a').getAttribute('href');
//
//   document.querySelector(target).scrollIntoView({
//     behavior: 'smooth'
//   });
//
//   return false
// }

export function     changeLocale(){
    if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'ru';
        localStorage.locale = 'ru';
    }
    else {
        this.$i18n.locale = 'en';
        localStorage.locale = 'en';
    }
}
export function scrollTo(target, targetNum){

    document.querySelector(target).scrollIntoView({
        behavior: 'smooth'
    });
    this.activeSlide = targetNum
}
export function animateAll(block){
    document.querySelectorAll(`${block} .anim1`).forEach(el=>{
        el.classList.remove('animate__animated','animate__fadeInUp');
    });
    document.querySelectorAll(`${block} .anim2`).forEach(el=>{
        el.classList.remove('animate__animated','animate__fadeInUp', 'animate__delay-2s');
    });
    document.querySelectorAll(`${block} .anim3`).forEach(el=>{
        el.classList.remove('animate__animated','animate__fadeInUp', 'animate__delay-3s');
    });
    document.querySelectorAll(`${block} .anim4`).forEach(el=>{
        el.classList.remove('animate__animated','animate__fadeInUp', 'animate__delay-4s');
    });
setTimeout(()=>{
    document.querySelectorAll(`${block} .anim1`).forEach(el=>{
        el.classList.add('animate__animated','animate__fadeInUp');
    });
    document.querySelectorAll(`${block} .anim2`).forEach(el=>{
        el.classList.add('animate__animated','animate__fadeInUp', 'animate__delay-2s');
    });
    document.querySelectorAll(`${block} .anim3`).forEach(el=>{
        el.classList.add('animate__animated','animate__fadeInUp', 'animate__delay-3s');
    });
    document.querySelectorAll(`${block} .anim4`).forEach(el=>{
        el.classList.add('animate__animated','animate__fadeInUp', 'animate__delay-4s');
    });
},100)

}


export default {
    en: {
        header: {
            login: "Sign in or register",
            lang: "Ру",
            link1prev: "payments",
            link1: "Online",
            and: "and",
            link2: "offline ",
            link3: "Technologies",
            writeUs: "WRITE TO US"
        },
        home: {
            h1: "All Essential Payment Services to Grow",
            h1_1: "Your Business",
            el1_h: " anywhere",
            el1_p1: "Online: on the website, with a link, in the app",
            el1_p2: "At POS",
            el1_p3: "On delivery",
            el1_p4: "Using self-service terminals",
            el2_h: "Help your customers pay  any way",
            el2_p1: "Bank cards Contactless payments:",
            el2_p2: "Apple Pay, Google Pay, MIR Pay",
            el2_p3: "Instant payment using QR code",
            el2_p4: "E-wallets",
            el3_h: " Fast and secure online payments",
            el3_p1: "Easy API integration",
            el3_p2: "Various online payment acceptance methods",
            el3_p3: "Large set of functions for any type of business",
            el3_p4: "High security standards",
            el4_h: "Advanced payment processing equipment and software",
            el4_p1: "Wireless POS terminals",
            el4_p2: "Mobile cashiers",
            el4_p3: "Self-service terminals",
            el5_h: "State-of-the-art technologies and solutions",
            el5_p1: "Fraud monitoring",
            el5_p2: "Multifunctional dashboard",
            el5_p3: "Tap to Phone technology and Wallet App",
            el6_h: "Our Solutions"

        },
        online:{
            h1: "Accept Payments Anytime  ",
            h1_1: "from Anywhere",
            h1_2: "in the World",
            el1_h: "Online payments",
            el2_h: "security features",
            el3_h: "On your website",
            el3_p1: "Choose the best method for your needs: fast API integration, pop-up payment page or iFrame",
            el4_h: "With a link",
            el4_p1: "Issue invoices from your Dashboard and send payment links using any messenger, email or sms",
            el5_h:"  Using subscription",
            el5_p1: "Set up recurring invoice mailing or automated debit ",
            el6_h: "Features",
            el7_h:"Payment Page Branding",
            el7_p1: "Payment form design in your company’s style. Use your logo and brand colors.",
            el8_h:"Batch Payments",
            el8_p1: "to your counterparties and contractors, suppliers and partners, employees and customers. Send payments based on recipient list in a batch, all at the same time, regardless of their location, currency and payment method. ",
            el9_h:"Tokenization",
            el9_p1: "to accept customers payments in one click. On the first purchase your customer payment details are saved and filled in automatically on subsequent payments. ",
            el10_h:"Analytics",
            el10_p1: "Monitor your transactions online, create financial reports, manage your payments from an easy-to-use Dashboard. ",
            el11_h:"Security",
            el12_h: "certification",
            el12_p1: "Your customer data is effectively protected. Monnect is certified according to the most recent PCI DSS Level 1 international payment system security standard.",
            el13_h: "protection",
            el13_p1: "Protection from Fraudulent Transactions Our Anti-Fraud system uses the latest machine learning technologies. Every payment is checked in real time, while suspicious transactions are blocked instantly."
        },
        offline: {
            h1: "Reliable",
            h1_1: "Payment",
            h1_2: "Acceptance Equipment",
            el1_h:" equipment",
            el2_h: "POS Terminals",
            el2_p1: "For all types of POS",
            el2_p2: "Advanced wireless terminals supporting any connection type: Вluetooth, Ethernet, Wi-Fi, mobile internet.",
            el3_h: "Mobile Cashiers and MiniPOS",
            el3_p1: "Compact devices to accept all types of payments:",
            el3_p2: "Bank cards;",
            el3_p3: "Contactless payments;",
            el3_p4: "Payments with QR codes",
            el4_h:"Tap to Phone",
            el4_p1: "Advanced solution for mobile business",
            el4_p2: "Tap to Phone technology turns any Android smartphone with an NFC module into a payment terminal. Install Wallet mobile app and accept payments in one tap:",
            el4_p3: "Supports all types of bank cards;",
            el4_p4: "Upon payment the receipt is automatically sent to the customer;",
            el4_p5: "Information on all transations is displayed in the App",
            el5_h: "ТSelf-Service Terminals and Kiosks",
            el5_p1: "Automate payment acceptance process",
            el5_p2: "Monitor transactions remotely, create reports based on receipt total, time, and type of payment. Receive data in 1C/CRM",
            el5_p3: "Accept cash and give change;",
            el5_p4: "All types of electronic funds transfer;",
            el5_p5: "Receipt printing;",
            el5_p6: "Scan barcodes on bills, loyalty and discount cards",
        },
        technology: {
            h1: "Easy-to-Use",
            h1_1: "Mobile",
            h1_2: "Secure",
            el1_h: "Multifunctional Dashboard",
            el2_h: "Tap to Phone &  Wallet App Anti-Fraud Protection",
            el3_h: "Take control of your finances",
            el3_p1: "Manage your financial flows and monitor online and offline transactions from a single dashboard.",
            el3_p2: "All information on payments (online, from mobile cashiers and self-service terminals) is transmitted into your Dashboard, so that you can collect statistics, create your financial analytics system and manage your business efficiently."  ,
            el4_h: " Transactions",
            el4_p1: " Issue invoices",
            el4_p2: "Set up recurring payments",
            el4_p3: "Configure batch payments",
            el4_p4: "Manage mobile cashiers",
            el5_h: "Analytics",
            el5_p1: "Monitor transactions in real time",
            el5_p2: "Collect data from all payment pages and devices",
            el5_p3: "Create financial reports",
            el6_h: "You don’t need additional equipment if you have Wallet App",
            el6_p1: "With our mobile application you can accept payments using any Android smartphone. Wallet App supports payments from any bank cards and with a QR code. All transactions are saved in the App, and the receipt is automatically sent to the customer via email, sms or displayed in the App as a QR code.\n",
            el6_p2: "Download and install the App",
            el6_p3: "Enter the amount of payment.",
            el6_p4: "Tap the card on the smartphone.",
            el6_p5: "Wait for transaction to complete.",
            el6_p6: "Get a receipt as a QR code",
            el7_h: "Anti-Fraud Protection",
            el8_h: "Protect yourself from fraudulent transactions, reduce chargebacks and false positives. Improve conversion rates and your business performance.",
            el8_p1: "Monnect Anti-Fraud module carefully analyzes each and every transation based on 100+ parameters.",
            el8_p2: "It analyzes your customers’ profiles and configures individual risk models for each company. Customized algorithms immediately and accurately predict whether the transaction is secure and protect your business from fraud.",


        },
        form:{
            h1: "your contacts",
            h2: " What would you like to say?",
            h3: "THANKS! YOUR MESSAGE HAS BEEN SENT!",
            name: "Name",
            email: "Email",
            message: "Enter your message...",
            btn1: "proceed",
            btn2: "send",
            btn3: "one more"
        }
    },
    ru: {
        header: {
            login: "Вход или регистрация",
            lang: "En",
            link1prev: "Платежи",
            link1: "онлайн",
            and: "и",
            link2: "офлайн",
            link3: "Технологии",
            writeUs: "НАПИСАТЬ НАМ"
        },
        home: {
            h1: "платёжные сервисы для роста вашего",
            h1_1: "бизнеса",
            el1_h: "и как",
            el1_p1: "Онлайн: на сайте, по ссылке, в приложении",
            el1_p2: "В точках продаж",
            el1_p3: "При доставки товаров и услуг",
            el1_p4: "Через терминалы самообслуживания",
            el2_h: "пусть клиенты платят где им удобно",
            el2_p1: "Банковскими картами Бесконтактными платежами:",
            el2_p2: "Apple Pay, Google Pay, МИР Pay",
            el2_p3: " Быстрыии платежами по QR-коду",
            el2_p4: "С электронных кошельков",
            el3_h: " Быстрые и безопасные  онлайн-платежи",
            el3_p1: "Простая API-интеграция",
            el3_p2: "Различные способы приема онлайн-платежей",
            el3_p3: "Большой набор функций для любого вида бизнеса",
            el3_p4: "Высокие стандарты безопасности",
            el4_h: "Современное  платежное оборудование и програмное обеспечение",
            el4_p1: "Беспроводные POS-терминалы",
            el4_p2: "Мобильные кассы",
            el4_p3: "Терминалы самообслуживания",
            el5_h: "Новейшие технологии и разработки",
            el5_p1: "Фрод-мониторинг",
            el5_p2: "Многофункциональный личный кабинет",
            el5_p3: "Tехнология Tap to Phone и приложение Wallet",
            el6_h: "Наши решения"

        },
        online:{
            h1: "Принимайте платежи в любое время",
            h1_1: "из любой",
            h1_2: "точки мира",
            el1_h: "онлайн платежи",
            el2_h: "функции безопасность",
            el3_h: "на сайте",
            el3_p1: "Выбирите оптимальное  решение: быстрая API - интеграция, всплывающая платёжная страница или окно i-Frame",
            el4_h: "по ссылке",
            el4_p1: "Выставляйте счета из Личного кабинета и отправляйте ссылку на оплату в мессенджер, по email или sms",
            el5_h:" по подписке",
            el5_p1: "Настройте рассылку регулярных счетов или автоматическое списание средств",
            el6_h: "функции",
            el7_h:"Собственный дизайн платежной страницы",
            el7_p1: "Оформление платежной формы под стиль вашего бренда. Используйте логотип и корпоративные цвета",
            el8_h:"Массовые выплаты",
            el8_p1: "Платите контрагентам и подрядчикам, поставщикам и партнерам, сотрудникам и клиентам. Отправляйте денежные средства по списку получателей одновременно одним пакетом, независимо от их местоположения, валюты или способа оплаты",
            el9_h:"Токенезация",
            el9_p1: "Для приема  платежей от  покупателей в один клик. При первой покупке платежные данные ваших клиентов сохраняются и при следующих оплатах подставляются автоматически",
            el10_h:"Аналитика",
            el10_p1: "Следите  за транзакциями онлайн, формируйте финансовые отчеты, управляйте платежами в удобном личном кабинете",
            el11_h:"безопасность",
            el12_h: "сертификация",
            el12_p1: "Данные ваших клиентов надежно защищены. Monnect сертифицирован по международному стандарту безопасности платежных систем PCI DSS Level 1 последней спецификации",
            el13_h: "защита",
            el13_p1: "Защита от мошеннических операций. В нашей Антифрод системе  используются новейшие методы машинного обучения. Каждый платеж проверяется в режиме реального времени, подозрительные транзакции моментально блокируются"
        },
        offline: {
            h1: "Надёжное",
            h1_1: "оборудование для",
            h1_2: "приёма платежей",
            el1_h:" оборудование",
            el2_h: "POS - терминалы",
            el2_p1: "Для любых точек продаж.",
            el2_p2: "Современные беспроводные терминалы с любым типом подключения: Вluetooth, Ethernet, Wi-Fi, мобильный интернет",
            el3_h: "Мобильные кассы и мини-терминалы",
            el3_p1: "Компактные устройства для приема всех видов оплат:",
            el3_p2: "банковских карт;",
            el3_p3: "бесконтактных платежей;",
            el3_p4: "оплат с помощью QR-кода",
            el4_h:"Tap to Phone",
            el4_p1: "Современное решение для мобильного бизнеса.",
            el4_p2: "Технология Tap To Phone превращает любой Android -смартфон с NFC модулем в платежный терминал. Установите  мобильное приложение Wallet и принимайте платежи в одно касание:",
            el4_p3: "работает с любыми банковскими картами;",
            el4_p4: "чек после оплаты автоматически отправляется покупателю;",
            el4_p5: "информация обо всех операций отображается в приложении",
            el5_h: "Терминалы самообслуживания и электронные кассиры",
            el5_p1: "Автоматизируйте процесс приёма платежей.",
            el5_p2: "Отслеживайте транзакции в удаленном режиме, формируйте отчеты по сумме чека,  времени, типу оплаты. Получайте данные в 1C/CRM",
            el5_p3: "приём наличных с выдачей сдачи;",
            el5_p4: "все виды безналичных платежей;",
            el5_p5: "печать чеков;",
            el5_p6: "считывание штрих кодов с квитанций, бонусных и скидочных карт",
        },
        technology: {
            h1: "Удобство",
            h1_1: "мобильность",
            h1_2: "безопасность",
            el1_h: "Много-функцио нальный  личный кабинет",
            el2_h: "Tap to Phone &  Wallet App Антифрод",
            el3_h: "Держите деньги под контролем",
            el3_p1: "Управляйте финансовыми потоками и следите за онлайн и офлайн транзакциями из одного окна.",
            el3_p2: " Информация обо всех платежах — онлайн, с мобильных касс и терминалов самообслуживания поступает в  Личный кабинет, что позволяет собирать статистику, выстраивать систему финансовой аналитики и эффективно управлять  своим бизнесом"  ,
            el4_h: " Операции",
            el4_p1: "выставляйте счета",
            el4_p2: "подключайте регулярные платежи",
            el4_p3: " настраивайте массовые выплаты",
            el4_p4: " управляйте мобильными кассами",
            el5_h: "Аналитика",
            el5_p1: "отслеживайте транзакции в режиме реального времени",
            el5_p2: "получайте данные со всех платежных страниц и  устройств",
            el5_p3: "формируйте финансовые отчеты",
            el6_h: "Не нужно дополнительного оборудования — достаточно Wallet App",
            el6_p1: "С помощью нашего мобильного приложения можно принимать платежи с любого Android- смартфона. Wallet App позволяет принимать оплаты с любых банковских карт и по QR-коду. Все проведенные операции сохраняются в приложении, чек автоматически отправляется покупателю по email, sms или отображается в приложении в виде QR-кода.",
            el6_p2: "Скачайте и установите приложение.",
            el6_p3: "Введите  сумму платежа.",
            el6_p4: "Приложите карту к смартфону.",
            el6_p5: "Дождитесь проведения операции.",
            el6_p6: "Получите чек в виде  QR-коде ",
            el7_h: "Антифрод",
            el8_h: "Защита от мошеннических операций, сокращение возвратных платежей и ложных блокировок. Конверсия растет— ваши бизнес-показатели улучшаются",
            el8_p1: "Антифрод модуль Monnect  тщательно анализирует каждую транзакцию по 100+параметрам",
            el8_p2: "Программа изучает профили  покупателей и настраивает индивидуальные риск-модели для каждой компании. Кастомизированные алгоритмы  мгновенно и точно предсказывают заслуживает ли транзакция доверия или нет и защищают ваш бизнес от недобросовестных клиентов",


        },
        form:{
            h1: "ваши контакты",
            h2: "что вы хотели сказать?",
            h3: "СПАСИБО! ВАШЕ СООБЩЕНИЕ ОТПРАВЛЕНО!",
            name: "Имя",
            email: "и адрес эл. почты",
            message: "ВВЕДИТЕ сообщение...",
            btn1: "продолжить",
            btn2: "отправить",
            btn3: "отправить ещё"
        }
    }
}

<template>
<div class="header d-flex align-items-center justify-content-between px-2 px-lg-5" >
  <div class="w-100 d-flex align-items-center justify-content-start">
    <router-link :to="{name: 'mobMenu'}"  v-if="$route.name!=='mobMenu'"  class="d-xl-none"><img src="img/burger.svg" alt=""></router-link>
    <a @click="$router.go(-1)" v-else  class="d-xl-none"><img src="img/burger.svg" alt=""></a>
    <span class="d-none d-xl-inline-block"> &nbsp;{{$i18n.t('header.link1prev')}}&nbsp; </span>
    <router-link :class="{'active': $route.name ==='Online'}" :to="{name: 'Online' }" class=" d-none d-xl-inline-block" >{{$i18n.t('header.link1')}}</router-link>
    <span class="d-none d-xl-inline-block"> &nbsp;{{$i18n.t('header.and')}}&nbsp; </span>
    <router-link :class="{'active': $route.name ==='Offline'}" :to="{name: 'Offline'}" class="pe-3 d-none d-xl-inline-block" >{{$i18n.t('header.link2')}}</router-link>
    <router-link :class="{'active': $route.name ==='Technology' || $route.name ==='TechnologyBlock'}" :to="{name: 'Technology'}" class="px-3 d-none d-xl-inline-block">{{$i18n.t('header.link3')}}</router-link>
  </div>
  <div class="text-center"><router-link :to="{name: 'Home'}">
    <img :src="isFirstSlide? 'img/Logo.svg': 'img/icon.svg'" alt="">
  </router-link></div>
  <div class="w-100 d-flex align-items-center justify-content-end">
<!--    <router-link :to="{name: 'phoneForm'}" :class="{'active': $route.name ==='phoneForm'}" class="px-3 d-none d-xl-inline-block" >{{$i18n.t('header.login')}}</router-link>-->
<!--    <a @click.prevent="changeLocale" class="px-3 d-none d-xl-inline-flex align-items-center pt-1" href=""> <i class="px-1  "><img style="margin-top: -2px;" class="" src="img/Flag.svg" alt=""></i> {{$i18n.t('header.lang')}} </a>-->
<!--    <router-link :to="{name: 'phoneForm'}"  class="d-xl-none"><img style="width: 30px; height: 30px" src="img/icons/arcticons_consorsbank-secureplus.svg" alt=""></router-link>-->
  </div>
  </div>
</template>

<script>
import {changeLocale} from '@/utills/all';
export default {

  name: "header",
  props: ['isFirstSlide'],
  methods:{
    changeLocale
  }
}
</script>

<style>
.header{
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
}
.header div:nth-child(1),
.header div:nth-child(3){
  flex: 0 0 40%;
  max-width: 40%;
}
.header div:nth-child(3){
  font-size: 10px;
  text-transform: none;
  color: #D2D2D2;
}
.header div:nth-child(2){
  flex: 0 0 20%;
  max-width: 20%;
}
@media screen and (max-width: 1500px) {
  .header{
    font-size: 11px;
  }
}
@media screen and (max-width: 1240px) {
  .header div:nth-child(1),
  .header div:nth-child(3){
    flex: 0 0 20%;
    max-width: 20%;
  }
  .header div:nth-child(2){
    flex: 0 0 60%;
    max-width: 60%;
  }
}
</style>

<link rel="stylesheet" href="../../Landing_Miractal_2/css/main.css">
<template>
<div class="main px-lg-5">
  <Header :isFirstSlide="isFirstSlide"/>
<!--  <a class="first_link  d-xl-inline-block"-->
<!--     :class="{'d-none': $route.name !== 'mobMenu', 'menu_el': $route.name ==='mobMenu' }"-->
<!--     href=""> <span>8 922 345-45-45</span><i>-->
<!--    <svg width="20" height="41" viewBox="0 0 20 41" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <g clip-path="url(#clip0_7_279)">-->
<!--        <path d="M10 25.71C12.7614 25.71 15 23.4714 15 20.71C15 17.9486 12.7614 15.71 10 15.71C7.23858 15.71 5 17.9486 5 20.71C5 23.4714 7.23858 25.71 10 25.71Z" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>-->
<!--        <path d="M10 40.71V0.710022" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>-->
<!--        <path d="M0 20.71H20" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>-->
<!--        <path d="M7 3.71002L10 0.710022L13 3.71002" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>-->
<!--      </g>-->
<!--      <defs>-->
<!--        <clipPath id="clip0_7_279">-->
<!--          <rect width="20" height="40.71" fill="white"/>-->
<!--        </clipPath>-->
<!--      </defs>-->
<!--    </svg>-->
<!--  </i></a>-->
  <router-link class="last_link d-xl-inline-block"
     :class="{'d-none': $route.name !== 'mobMenu', 'menu_el': $route.name ==='mobMenu' }"
     :to="{name: 'writeForm'}"><i>
    <svg width="20" height="41" viewBox="0 0 20 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7_279)">
        <path d="M10 25.71C12.7614 25.71 15 23.4714 15 20.71C15 17.9486 12.7614 15.71 10 15.71C7.23858 15.71 5 17.9486 5 20.71C5 23.4714 7.23858 25.71 10 25.71Z" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
        <path d="M10 40.71V0.710022" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
        <path d="M0 20.71H20" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
        <path d="M7 3.71002L10 0.710022L13 3.71002" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
      </g>
      <defs>
        <clipPath id="clip0_7_279">
          <rect width="20" height="40.71" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </i><span>{{$i18n.t('header.writeUs')}}</span></router-link>
  <router-view
      :pageCounters="pageCounters"
               @count="count"
               @slide="slide"
  />
  <div class="copy">
    Monnect ⓒ 2010 — 2022
  </div>

</div>
</template>
<style>
  @import "./assets/css/normalize.css";
  @import "./assets/css/bootstrap.css";
  @import "./assets/css/main.css";
</style>
<script>


  import Header from "@/components/header";

  export default {
    components: {Header},
    data(){
      return{
        locale: "",
        isFirstSlide: true,
        pageCounters: {
          tech: 1,
          on: 1,
          off: 1,
          home: 1
        },
      }
    },
    methods: {
      setLocale(lang){
        this.$i18n.locale = lang;
        localStorage.locale = lang;
      },
      slide(e){
        this.isFirstSlide = e === 1
      },
      count(e){
        console.log(e);
        this.pageCounters[e] = this.pageCounters[e] === 1 ? 2 : this.pageCounters[e] === 2 ? 3 : 1
      }
    },
    created() {
      // if (localStorage.locale === 'ru' || localStorage.locale === 'en' ){
      //
      //    this.$i18n.locale  = localStorage.locale
      // }else {
        localStorage.locale = "en";
         this.$i18n.locale = "en";
      // }
    }
  }
</script>
<style>
.main{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  /*padding-top: 80px;*/
}
.first_link i, .last_link i{
  transform: rotate(-90deg);
  display: inline-block;
  padding: 0 20px;
}
.first_link, .last_link{
  position: fixed;
  top: 50%;
  left: -100px;
  transform: rotate(90deg);
  font-weight: 400;
  z-index: 1004;

}
.last_link{
  transform: rotate(-90deg);
  right: -90px;
  left: auto;
}
.menu_el svg path{
  stroke: #fff;
}
.menu_el{
  top: 70%;
}
.last_link i{
  transform: rotate(90deg);
}
.scroll_link{
  position: fixed;
  left: 50%;
  display: inline-block;
  margin-left: -15px;
  bottom: 10px;
  -webkit-animation: wiggle 1.5s infinite;
  animation: wiggle 1.5s infinite;
  z-index: 1000;


}
.scroll_link svg{
  transition: .5s;
}
.scroll_link:hover svg{
  transform: scale(1.3);
}
.scroll_link:hover path{
  fill: rgba(118, 196, 79, 1);
  stroke: rgba(118, 196, 79, 1);
}
.scroll_link.lastScreen svg{
transform: rotate(180deg);
}
.copy{
  position: fixed;
  right: 100px;
  bottom: 50px;
  color: rgba(0, 0, 0, 0.2);
  font-weight: 300;
  font-size: 11px;
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
 40% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  45% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  85% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes wiggle {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  45% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  85% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

</style>

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/online',
    name: 'Online',
    component: () => import(/* webpackChunkName: "about" */ '../views/Online.vue')
  },
  {
    path: '/offline',
    name: 'Offline',
    component: () => import(/* webpackChunkName: "about" */ '../views/Offline.vue')
  },
  {
    path: '/technology',
    name: 'Technology',
    component: () => import(/* webpackChunkName: "about" */ '../views/Technology.vue'),
  },
  {
    path: '/technology/:code',
    name: 'TechnologyBlock',
    component: () => import(/* webpackChunkName: "about" */ '../views/Technology.vue'),
    props:true
  },
  {
    path: '/menu',
    name: 'mobMenu',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobMenu.vue')
  },
  {
    path: '/phone',
    name: 'phoneForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/phoneForm.vue')
  },
  {
    path: '/form',
    name: 'writeForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/writeForm.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

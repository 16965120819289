import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createI18n } from "vue-i18n";
import messages from "./i18n/messages";
import Vue3TouchEvents from "vue3-touch-events";
import 'vue-fullpage.js/dist/style.css';
// import './fullpage.scrollHorizontally.min';
import VueFullPage from 'vue-fullpage.js';
import 'animate.css';

const i18n = createI18n({
  locale: 'en',
  messages
})

createApp(App)
  .use(router)
  .use(i18n)
  .use(Vue3TouchEvents)
  .use(VueFullPage)
  .mount('#app')
